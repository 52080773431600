import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, Card, Icon } from '@intility/bifrost-react';
import { useContext } from 'react';

import { ModalContext } from '@/context/ModalContext';
import { useBasketActions } from '@/stores/basket';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import { BasketModal } from './BasketModal';

interface FeaturedProductCardProps {
  product: DataPlanDto;
}

export const FeaturedProductCard = ({ product }: FeaturedProductCardProps) => {
  const { handleModal } = useContext(ModalContext);
  const { addToBasket } = useBasketActions();

  const openModal = () => {
    addToBasket(product);
    handleModal(<BasketModal product={product} />, 'Abonnement lagt til', faCheckCircle);
  };

  const sellingPoints = product.sellingPoints;

  return (
    <Card
      className='flex flex-col rounded-lg border border-bfc-base-c-dimmed'
      data-testid='featured-product'
    >
      <div className='m-0 flex flex-col gap-bfs-4 border-b border-bfc-base-dimmed p-bfs-16'>
        <h4 className={'font-display text-xl font-semibold'}>{product.name}</h4>

        <div className='mt-bfs-4 flex items-center gap-bfs-12'>
          <p className='font-medium'>{product.customerMonthlyPrice} kr/mnd</p>
          <Badge pill>Firmaavtale</Badge>
        </div>
      </div>

      <Card.Content className={'m-0 flex grow flex-col p-bfs-24 text-left'}>
        <ul className='mb-bfs-24 flex list-none flex-col gap-bfs-8'>
          {sellingPoints.map((sellingPoint, i) => (
            <li key={i} className='flex'>
              <Icon className='mr-bfs-8 mt-bfs-4' icon={faCheck} color='var(--bfc-theme)' />
              {sellingPoint.text}
            </li>
          ))}
        </ul>

        <div className={'mt-auto'}>
          <Button
            icon={faPlus}
            onClick={openModal}
            variant={'filled'}
            state='neutral'
            className='w-full'
          >
            Legg til
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};
