import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, Icon, Message, Skeleton } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

import { routes } from '@/routes/config';
import { useDataPlans } from '../api/getDataPlans';
import { categoryMetadata } from '../assets/metadata';
import type { CategoryMetadata, SubscriptionProductType } from '../types/products';

interface ProductCategoryOverviewCardProps {
  category: SubscriptionProductType;
}

export const ProductCategoryOverviewCard = ({ category }: ProductCategoryOverviewCardProps) => {
  const dataPlans = useDataPlans();

  const categoryInfo = categoryMetadata[category];
  const dataPlanPrices: number[] = [];

  for (const dataPlan of dataPlans.data?.items ?? []) {
    if (dataPlan.subscriptionCategoryType !== category) continue;

    dataPlanPrices.push(dataPlan.customerMonthlyPrice);
  }

  const cheapestDataPlan = Math.min(...dataPlanPrices);

  return (
    <Card align='center' className='rounded-xl border border-bfc-base-c-dimmed'>
      <CategoryHeader categoryMetadata={categoryInfo} />
      <CategorySellingPoints categoryMetadata={categoryInfo} />

      <Card.Content className='m-0 p-bfs-24'>
        {dataPlans.isError ? (
          <Message state='alert'>
            Kunne ikke hente abonnementer. Kontakt Intility hvis problemet vedvarer.
          </Message>
        ) : (
          <>
            {dataPlans.isPending ? (
              <Skeleton>
                <Skeleton.Text data-testid='loading-skeleton' />
              </Skeleton>
            ) : (
              <p>
                {dataPlanPrices.length ? `fra ${cheapestDataPlan}kr/mnd` : 'Kontakt oss for priser'}
              </p>
            )}

            <Link to={`${routes.products.overview.path}/${category.toLowerCase()}`}>
              <Button state='neutral' variant='filled' className='mt-bfs-16 w-full'>
                {categoryInfo.buttonText}
                <Icon icon={faAngleRight} className='ml-bfs-8' />
              </Button>
            </Link>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

const CategoryHeader = ({ categoryMetadata }: { categoryMetadata: CategoryMetadata }) => {
  const { categoryHeader } = categoryMetadata;

  return (
    <Card.Content className='m-0 p-bfs-24'>
      <h4 className='font-display text-xl font-bold'>{categoryHeader}</h4>
    </Card.Content>
  );
};

const CategorySellingPoints = ({ categoryMetadata }: { categoryMetadata: CategoryMetadata }) => {
  const { categoryIngress, sellingPoints } = categoryMetadata;

  return (
    <Card.Content className='m-0 !p-bfs-24 text-left'>
      <p className='bf-p bfc-base'>{categoryIngress}</p>

      <ul className='mt-bfs-16 flex list-none flex-col gap-bfs-12'>
        {sellingPoints.map(sellingPoint => (
          <li key={sellingPoint} className='flex'>
            <Icon icon={faCheck} className='mr-bfs-12 mt-bfs-4' color='var(--bfc-theme)' />
            <p className={'bf-p bfc-base m-0'}>{sellingPoint}</p>
          </li>
        ))}
      </ul>
    </Card.Content>
  );
};
